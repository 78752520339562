import React from "react"
import "./banner.styles.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import down from "../../images/down.svg"

const Banner = () => {
  return (
    <div>
      <div className="background" id="top">
        <div className="container">
          <div className="banner_content">
            {" "}
            <h1>Building ML products shouldn’t be so difficult.</h1>
            <hr />
            <p>
              LeanML best practices make your product come to life easier &
              faster. Scroll down to learn how to kickstart your idea today.
            </p>
            <AnchorLink to="/#leanMl">
              <button className="btn">Learn More</button>
            </AnchorLink>
          </div>
          <AnchorLink to="/#realities">
            <div className="slider">
              <img src={down} ait="down icon" />
            </div>
          </AnchorLink>
        </div>
      </div>
    </div>
  )
}

export default Banner
