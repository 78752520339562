import React from "react"
import Image from "../image"
import check from "../../images/check.svg"
import "./reality.styles.scss"
import Carousel from "react-elastic-carousel"

const Reality = () => {
  return (
    <>
      <div className="reality_bg " id="realities">
        <div className="container">
          <div className="flex_container">
            <div className="reality_image">
              <Image name="realities" />
            </div>
            <div className="reality_content">
              <h2>The Realities of ML</h2>
              <hr />
              <p>
                So, you want to build some fancy ML software. Or maybe you are
                planning the next unicorn, built on a foundation of ML.
              </p>
              <p>
                Before you begin, familiarize yourself with the core realities
                of building and utilizing ML.
              </p>
            </div>
          </div>
          <div>
            <Carousel
              breakPoints={[
                { width: 200, itemsToShow: 1 },
                { width: 650, itemsToShow: 1 },
                { width: 750, itemsToShow: 2 },
                { width: 1150, itemsToShow: 3 },
              ]}
              itemsToShow={3}
            >
              <div className="news-card">
                <img src={check} alt="check" />
                <h5>
                  Reality 1 {/* Reality 1, 2, 3, etc: This should be bold. */}
                </h5>

                <p>
                  Machine learning software is extremely expensive to build.
                </p>
              </div>
              <div className="news-card">
                <img src={check} alt="check" />
                <h5>
                  Reality 2
                </h5>

                <p>
                  All machine learning systems produce error. Often, it’s more
                  error than you would expect.
                </p>
              </div>
              <div className="news-card">
                <img src={check} alt="check" />
                <h5>
                  Reality 3
                </h5>

                <p>
                  A single ML model must be built and re-built dozens of times during initial development, while tuning various options in order to produce the right output.
                </p>
              </div>
              <div className="news-card">
                <img src={check} alt="check" />
                <h5>
                  Reality 4
                </h5>

                <p>
                  Results may change at any time due to changes in external data
                  and external dependencies (such as cloud services or ML APIs)
                  that will require a new model, new feature extractors, and a
                  newly-tuned system.
                </p>
              </div>
              <div className="news-card">
                <img src={check} alt="check" />
                <h5>
                  Reality 5
                </h5>

                <p>
                  Required resources are specialized and expensive. ML
                  components must be maintained by ML software engineers with
                  years or decades of experience, at a cost
                  ~50% higher than non-ML software engineers.
                </p>
              </div>
            </Carousel>
            <div id="leanMl"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reality
