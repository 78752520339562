import React from "react"
import "./features.styles.css"
import Image from "../image"
import { Link, graphql, StaticQuery } from "gatsby"

const Features = () => {
  return (
    <div className="features_bg">
      <div style={{ marginTop: "70px" }}>
        <div className="container">
          <div className="features_content">
            <h2>LeanML -- a better way</h2>
            <hr />
            <p>
              There is a better, faster and more efficient way to build ML
              products and systems that navigates the realities above. <br />
              <br /> These are the 4 central tenets of LeanML that are an
              extension to the Lean Startup methodology. If you’re not familiar
              with Lean Startup, we’d recommend starting there first.
            </p>
          </div>
          <div className="features grey">
            <div className="features_content1">
              <h2>Humans First, ML Second</h2>
              <hr />
              <p>
                All Lean Startups know that you figure out the user need first,
                then select the technology required. But with ML, it goes a step
                further: the human team must be able to conduct the entire ML
                task by hand, before the ML is built. If you, the human, can’t
                output what you’re asking an ML system to do, you’re not going
                to be successful.
              </p>
             <Link to="/resources"> 
              <button className="btn">Learn More</button>
              </Link>
            </div>
            <div className="image">
              <Image name="g10" alt="g10" />
            </div>
          </div>
          <div className="features blue">
            <div className="imagel">
              <Image name="g12" alt="g12" />
            </div>
            <div className="features_content1">
              <h2>
                Estimate Error & Collect User Response
              </h2>
              <hr />
              <p>
                There’s error in any ML system. In fact, there’s often more
                error than you would expect. Your choice of ML solution will be
                driven by the balance between different types of error produced
                by different technologies, and your user’s tolerance for error
                given the product use case. How much error will the user
                tolerate? What type of errors will the user tolerate?
              </p>
              <Link to="/resources"> 
              <button className="btn">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="features grey">
            <div className="features_content1">
              <h2>Designing for Error</h2>
              <hr />
              <p>
                Let us repeat -- all ML systems have error! But, that’s okay,
                and that’s because you can plan for it. After determining user
                tolerance for error, your process or product perspective should
                include design to handle the error. For example, do you need two
                different ML systems, one to generate and another to rank? Do
                you need a human verification step into the process before
                showing results?
              </p>
              <Link to="/resources"> 
              <button className="btn">Learn More</button>
              </Link>
            </div>
            <div className="image1">
              <Image name="g13" alt="g13" />
            </div>
          </div>
          <div className="features blue">
            <div className="imagel">
              <Image name="g11" alt="g11" />
            </div>
            <div className="features_content1">
              <h2>
                It’s probably already been built
              </h2>
              <hr />
              <p>
                Worldwide communities of thousands of computer scientists spend
                their entire careers developing machine learning algorithms,
                studying how they behave on different types of data, and
                producing open-source libraries for fast and accurate
                implementation. In addition, commercial platforms are available
                that wrap these libraries into super-easy user interfaces,
                complete with a few models to match your data. Your ML task
                isn’t unique: there is an entire background of previous research
                that will guide you in how you should and shouldn’t build your
                solution, and save you enormous amounts of time and resources.
              </p>
              <Link to="/resources"> 
              <button className="btn">Learn More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
