import React from "react"
import "./resources.styles.scss"
import "../blog/reources.styles.scss"
import check from "../../images/check.svg"
import Carousel from "react-elastic-carousel"
import { Link, graphql, StaticQuery } from "gatsby"
import arrow from "../../images/arrow_right.svg"
import "../../styles.css"

const Resources = props => {
  const { data } = props
  const { edges: posts } = data.allMarkdownRemark
  return (
    <div className="resources_bg">
      <div className="container">
        <div className="resources_content">
          <h2>
            <span className="ourtitle">
              LeanML Resources
            </span>
          </h2>
          <hr />
          <p>
            There’s a wealth of resources available to help make your ML product
            or company a success. Geared towards the non-technical audience,
            they’ll help you think about your product in a brand new light.
          </p>
        </div>
        <div
          style={{ paddingTop: "80px", margin: "0 auto" }}
          className="resource-body home"
        >
          <Carousel
            itemsToShow={3}
            style={{ marginTop: "50px" }}
            breakPoints={[
              { width: 200, itemsToShow: 1 },
              { width: 650, itemsToShow: 1 },
              { width: 750, itemsToShow: 2 },
              { width: 1150, itemsToShow: 3 },
            ]}
          >
            {posts &&
              posts.map(({ node: post }) => (
                <Link to={`/resources/${post.frontmatter.path}`}>
                  <div className="news-card blog_news">
                    <div
                      style={{
                        backgroundImage:
                          "url(" + post.frontmatter.thumbnail.publicURL + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "280px",
                      }}
                      className="blog_bg"
                    ></div>{" "}
                    <div
                      style={{
                        maxWidth: "400px",
                        textAlign: "left",
                        padding: "10px 22px",
                        minHeight: "120px",
                      }}
                    >
                      <h5>
                        <span className="resources-blog-title">
                          {post.frontmatter.blogtitle}
                        </span>
                      </h5>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 22px",
                      }}
                    >
                      <div className="date" style={{ fontSize: "14px" }}>
                        <span className="resources-blog-date">
                          {post.frontmatter.date}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#2C4888",
                            textDecoration: "none",
                            paddingRight: "3px",
                          }}
                        >
                          <span className="resources-blog-readmore">
                            Read more
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <img src={arrow} alt="right arrow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </Carousel>
          <div className="resource-btn">
            <Link to="/resources">
              <button className="btn">View All Resources</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {}
        ) {
          edges {
            node {
              excerpt(pruneLength: 450)
              id
              frontmatter {
                path
                blogtitle
                author
                thumbnail {
                  publicURL
                }
                date(formatString: "MM.DD.YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Resources data={data} count={count} />}
  />
)
