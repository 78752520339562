import * as React from "react"
import { globalHistory } from "@reach/router"
import Header from "../components/header/header.component"
import "../styles.css"
import Banner from "../components/banner/banner.component"
import Reality from "../components/reality/reality.component"
import Features from "../components/features/features.component"
import Resources from "../components/resources/resources.component"
import Footer from "../components/footer/footer.component"
import SEO from "../components/seo"
const IndexPage = () => {
  return (
    <div>
      <SEO title="Home" />
      <Header url={globalHistory.location.pathname} />
      <Banner />
      <Reality />
      <Features />
      <Resources />
      <Footer url={globalHistory.location.pathname} top="top" />
    </div>
  )
}

export default IndexPage
